<template>
  <div class="px-3.5 py-5 mx-auto w-full max-w-7xl">
    <Text :content="title" size="3xl" color="gray-800" weight="bold" />
    <Text :content="desc" size="base" color="gray-800" custom-class="mt-2.5" />
    <div class="w-full flex pt-2.5 pb-6 border-b items-center">
      <MultiSelectDropdown
        :options="selectOptions()"
        v-model:selected="selectedSchools"
        :btn-color="themeColor"
        displayAddButton="false"
        @onRemove="item => updateUserSchoolList(schoolList && schoolList.find(s => s && s.name === item))"
      />
      <Button
        custom-class="text-base px-6 font-bold ml-2"
        :content="compareLabel"
        :class="`bg-${themeColor}`"
        :click="() => onCompare()"
      />
    </div>
    <div class="w-full border-b py-6">
      <div
        class="w-870px flex flex-col justify-center items-center relative h-96"
        v-if="compareSchoolList.length > 0"
      >
        <div class="chart-type">
          <SimpleRadioGroup
            v-model:selectedItem="chartView"
            :items="chartTypeOptions"
            inline="true"
          />
        </div>
        <v-chart
          :option="chartOptions"
          :init-options="initOptions"
          auto-resize
        />
        <div v-if="homeData && campusData" class="flex items-center w-full -mt-2 py-3">
          <div class="mr-0">
            <p class="font-semibold" style="font-size: 10px; color: rgba(41, 186, 172, 0.6)">GRANTS</p>
            <p class="font-semibold" style="font-size: 10px; color: rgba(254, 144, 8, 0.6)">LOANS</p>
            <p class="font-semibold" style="font-size: 10px; color: rgba(41, 186, 172, 1)">UPFRONT COST</p>
          </div>
          <div v-for="(item, i) in getData()" style="width: 87.5px;" class="px-4">
            <p class="font-semibold" style="font-size: 10px; color: rgba(41, 186, 172, 0.6)">{{ `${numeral(Math.round((chartView?.value === 'atHome' ? homeData : campusData)?.['GrantsData']?.[i]?.value)).format("$0,0")}` }}</p>
            <p class="font-semibold" style="font-size: 10px; color: rgba(254, 144, 8, 0.6)">{{ `${numeral(Math.round((chartView?.value === 'atHome' ? homeData : campusData)?.['LoanData']?.[i]?.value)).format("$0,0")}` }}</p>
            <p class="font-semibold" style="font-size: 10px; color: rgba(41, 186, 172, 1)">{{ `${numeral(Math.round((chartView?.value === 'atHome' ? homeData : campusData)?.['FinalCostData']?.[i]?.value)).format("$0,0")}` }}</p>
          </div>
        </div>
      </div>
      <div class="text-center p-20" v-else>
        <Text
          content="No colleges were selected to compare list. Select colleges above to compare"
          size="base"
          color="gray-800"
          custom-class="mt-2.5"
        />
      </div>
    </div>
    <div
      class="w-full flex justify-center space-x-4 border-b pt-5 pb-12"
      v-if="compareSchoolList.length > 0"
    >
      <!--<SchoolCompareCard v-bind="compareProps"/>
			<SchoolCompareCard v-bind="compareProps"/>-->
      <div
        class="
          clearfix
          pt-5
          pb-10
          fix-table-wrapper
          finaid-border-top
          compare-table-main-block-container
        "
      >
        <div
          class="inner-fix-table-wrapper overflow-x-scroll w-screen max-w-7xl"
        >
          <table class="compare-table text-finaid-black fw-500 fs-14 m-b-10">
            <div
              v-if="!isPaid"
              class="
                absolute
                border
                rounded
                z-50
                left-56
                -right-4
                bg-white
                opacity-50
              "
              style="height: 570px; backdrop-filter: blur(6px)"
            >
              <div class="flex justify-center items-center w-full h-full">
                <Badge
                  label="Locked"
                  iconName="LockClosedIcon"
                  bgColor="white"
                  :isRounded="false"
                  :isLeft="true"
                  class="border border-gray-700"
                  textColor="gray-700"
                  :py="1"
                  :px="2"
                />
              </div>
            </div>
            <thead class="sticky">
              <tr>
                <th class="fix-table-col bg-white h-full z-20"></th>
                <th
                  class="p-2 bg-white relative z-50"
                  v-for="(school, listIndex) in compareSchoolList"
                >
                  <div
                    class="
                      w-60
                      h-32
                      relative
                      border
                      mb-2
                      rounded
                      flex
                      justify-center
                      p-2.5
                      items-center
                    "
                  >
                    <div
                      class="
                        absolute
                        w-8
                        h-8
                        left-0
                        top-0
                        cursor-pointer
                        flex
                        border
                        rounded
                        justify-center
                        items-center
                      "
                      @click="() => updateUserSchoolList(school)"
                    >
                      <Icon name="TrashIcon" size="4" color="gray-500" />
                    </div>
                    <img
                      :src="
                        school?.logo?.url ||
                        'https://faid-media.s3.us-west-1.amazonaws.com/logos/faid-icon.png'
                      "
                      class="h-full"
                    />
                  </div>
                  <ButtonWithIcon
                    label="Remove From College List"
                    iconName="MinusCircleIcon"
                    text-color="yellow-500"
                    class="
                      w-full
                      bg-white
                      border border-yellow-500
                      text-xs
                      mb-2
                    "
                    :leading="true"
                    @click="() => updateUserSchoolList(school)"
                  />
                </th>
              </tr>
            </thead>
            <tbody class="compare-section">
              <tr>
                <th class="fix-table-col bg-white">School</th>
                <template v-for="(school, listIndex) in compareSchoolList">
                  <td
                    align="left py-2.5"
                    :style="isPaid ? null : 'filter: blur(7px);'"
                  >
                    {{ school.name }}
                  </td>
                </template>
              </tr>
              <tr>
                <th class="fix-table-col">Grants/yr</th>
                <template v-for="(school, listIndex) in compareSchoolList">
                  <td :style="isPaid ? null : 'filter: blur(7px);'">
                    <div v-if="school.id">
                      <ButtonWithIcon
                        label="Locked"
                        iconName="LockClosedIcon"
                        text-color="gray-500"
                        icon-size="3"
                        class="
                          w-fit
                          bg-white
                          border border-gray-200
                          text-xs
                          px-1
                          py-0.5
                        "
                        :leading="true"
                        v-if="isLocked(school)"
                      />
                      <div v-else>
                        {{ getCalculatedData(school, "grants", "onCampus") }}
                      </div>
                    </div>
                  </td>
                </template>
              </tr>
              <tr>
                <th class="fix-table-col bg-white">Upfront Cost/yr</th>
                <template v-for="(school, listIndex) in compareSchoolList">
                  <td :style="isPaid ? null : 'filter: blur(7px);'">
                    <div v-if="school.id">
                      <ButtonWithIcon
                        label="Locked"
                        iconName="LockClosedIcon"
                        text-color="gray-500"
                        icon-size="3"
                        class="
                          w-fit
                          bg-white
                          border border-gray-200
                          text-xs
                          px-1
                          py-0.5
                        "
                        :leading="true"
                        v-if="isLocked(school)"
                      />
                      <strong class="text-primary text-xl" v-else>
                        {{
                          getCalculatedData(school, "outOfPocket", "onCampus")
                        }}
                      </strong>
                    </div>
                  </td>
                </template>
              </tr>
            </tbody>
            <tbody class="compare-section">
              <tr>
                <th class="fix-table-col pt-5 pr-0 flex align-middle">
                  <Icon
                    name="LibraryIcon"
                    size="5"
                    color="gray-500 inline mr-3"
                  /><b>Living On Campus</b>
                </th>
                <td height="50px"></td>
              </tr>
              <tr>
                <th class="fix-table-col">Original Cost</th>
                <template v-for="(school, listIndex) in compareSchoolList">
                  <td :style="isPaid ? null : 'filter: blur(7px);'">
                    <div v-if="school.id">
                      <ButtonWithIcon
                        label="Locked"
                        iconName="LockClosedIcon"
                        text-color="gray-500"
                        icon-size="3"
                        class="
                          w-fit
                          bg-white
                          border border-gray-200
                          text-xs
                          px-1
                          py-0.5
                        "
                        :leading="true"
                        v-if="isLocked(school)"
                      />
                      <div v-else>
                        {{ getCalculatedData(school, "cost", "onCampus") }}
                      </div>
                    </div>
                  </td>
                </template>
              </tr>
              <tr>
                <th class="fix-table-col bg-white">Grants</th>
                <template v-for="(school, listIndex) in compareSchoolList">
                  <td :style="isPaid ? null : 'filter: blur(7px);'">
                    <div v-if="school.id">
                      <ButtonWithIcon
                        label="Locked"
                        iconName="LockClosedIcon"
                        text-color="gray-500"
                        icon-size="3"
                        class="
                          w-fit
                          bg-white
                          border border-gray-200
                          text-xs
                          px-1
                          py-0.5
                        "
                        :leading="true"
                        v-if="isLocked(school)"
                      />
                      <div v-else>
                        {{ getCalculatedData(school, "grants", "onCampus") }}
                      </div>
                    </div>
                  </td>
                </template>
              </tr>
              <tr>
                <th class="fix-table-col">Loans</th>
                <template v-for="(school, listIndex) in compareSchoolList">
                  <td :style="isPaid ? null : 'filter: blur(7px);'">
                    <div v-if="school.id">
                      <ButtonWithIcon
                        label="Locked"
                        iconName="LockClosedIcon"
                        text-color="gray-500"
                        icon-size="3"
                        class="
                          w-fit
                          bg-white
                          border border-gray-200
                          text-xs
                          px-1
                          py-0.5
                        "
                        :leading="true"
                        v-if="isLocked(school)"
                      />
                      <div v-else>
                        {{ getCalculatedData(school, "loans", "onCampus") }}
                      </div>
                    </div>
                  </td>
                </template>
              </tr>
              <tr>
                <th class="fix-table-col bg-white">Upfront Cost/yr</th>
                <template v-for="(school, listIndex) in compareSchoolList">
                  <td :style="isPaid ? null : 'filter: blur(7px);'">
                    <div v-if="school.id">
                      <ButtonWithIcon
                        label="Locked"
                        iconName="LockClosedIcon"
                        text-color="gray-500"
                        icon-size="3"
                        class="
                          w-fit
                          bg-white
                          border border-gray-200
                          text-xs
                          px-1
                          py-0.5
                        "
                        :leading="true"
                        v-if="isLocked(school)"
                      />
                      <strong class="text-primary" v-else>
                        {{
                          getCalculatedData(school, "outOfPocket", "onCampus")
                        }}
                      </strong>
                    </div>
                  </td>
                </template>
              </tr>
            </tbody>
            <tbody class="compare-section">
              <tr>
                <th class="fix-table-col pt-5 pr-0 flex align-middle">
                  <Icon
                    name="HomeIcon"
                    size="5"
                    color="gray-500 inline mr-3"
                  /><b>Living at home</b>
                </th>
                <td height="50px"></td>
              </tr>
              <tr>
                <th class="fix-table-col">Original Cost</th>
                <template v-for="(school, listIndex) in compareSchoolList">
                  <td :style="isPaid ? null : 'filter: blur(7px);'">
                    <div v-if="isInStateSchool(school)">
                      <div v-if="school.id">
                        <ButtonWithIcon
                          label="Locked"
                          iconName="LockClosedIcon"
                          text-color="gray-500"
                          icon-size="3"
                          class="
                            w-fit
                            bg-white
                            border border-gray-200
                            text-xs
                            px-1
                            py-0.5
                          "
                          :leading="true"
                          v-if="isLocked(school)"
                        />
                        <div v-else>
                          {{ homeValuesShow(school) ? getCalculatedData(school, "cost", "atHome"): '-' }}
                        </div>
                      </div>
                    </div>
                    <div v-else>&nbsp;-&nbsp;</div>
                  </td>
                </template>
              </tr>
              <tr>
                <th class="fix-table-col bg-white">Grants</th>
                <template v-for="(school, listIndex) in compareSchoolList">
                  <td :style="isPaid ? null : 'filter: blur(7px);'">
                    <div v-if="isInStateSchool(school)">
                      <div v-if="school.id">
                        <ButtonWithIcon
                          label="Locked"
                          iconName="LockClosedIcon"
                          text-color="gray-500"
                          icon-size="3"
                          class="
                            w-fit
                            bg-white
                            border border-gray-200
                            text-xs
                            px-1
                            py-0.5
                          "
                          :leading="true"
                          v-if="isLocked(school)"
                        />
                        <div v-else>
                          {{ homeValuesShow(school) ? getCalculatedData(school, "grants", "atHome"): '-' }}
                        </div>
                      </div>
                    </div>
                    <div v-else>&nbsp;-&nbsp;</div>
                  </td>
                </template>
              </tr>
              <tr>
                <th class="fix-table-col">Loan</th>
                <template v-for="(school, listIndex) in compareSchoolList">
                  <td :style="isPaid ? null : 'filter: blur(7px);'">
                    <div v-if="isInStateSchool(school)">
                      <div v-if="school.id">
                        <ButtonWithIcon
                          label="Locked"
                          iconName="LockClosedIcon"
                          text-color="gray-500"
                          icon-size="3"
                          class="
                            w-fit
                            bg-white
                            border border-gray-200
                            text-xs
                            px-1
                            py-0.5
                          "
                          :leading="true"
                          v-if="isLocked(school)"
                        />
                        <div v-else>
                          {{ homeValuesShow(school) ? getCalculatedData(school, "loans", "atHome"): '-' }}
                        </div>
                      </div>
                    </div>
                    <div v-else>&nbsp;-&nbsp;</div>
                  </td>
                </template>
              </tr>
              <tr>
                <th class="fix-table-col bg-white">Upfront Cost/yr</th>
                <template v-for="(school, listIndex) in compareSchoolList">
                  <td :style="isPaid ? null : 'filter: blur(7px);'">
                    <div v-if="isInStateSchool(school)">
                      <div v-if="school.id">
                        <ButtonWithIcon
                          label="Locked"
                          iconName="LockClosedIcon"
                          text-color="gray-500"
                          icon-size="3"
                          class="
                            w-fit
                            bg-white
                            border border-gray-200
                            text-xs
                            px-1
                            py-0.5
                          "
                          :leading="true"
                          v-if="isLocked(school)"
                        />
                        <strong class="text-primary" v-else>
                          {{
                            homeValuesShow(school) ? getCalculatedData(school, "outOfPocket", "atHome"): '-'
                          }}
                        </strong>
                      </div>
                    </div>
                    <div v-else>&nbsp;-&nbsp;</div>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="w-full border-b py-8">
      <Text
        :content="summary.title"
        size="3xl"
        color="gray-800"
        weight="bold"
        custom-class="mt-2"
      />
      <div class="w-full flex flex-col sm:flex-row space-x-0 sm:space-x-6 py-5">
        <div class="flex-1" v-for="card in getSummary()">
          <Text
            :content="card.title"
            size="sm"
            color="gray-800"
            weight="bold"
            custom-class="py-5 px-2.5"
          />
          <div
            class="bg-gray-100 h-40 flex justify-center px-5 items-center"
            v-if="card.list && card.list.length === 0"
          >
            <Text
              align="center"
              :content="summary.emptyLabel"
              size="1xl"
              color="gray-600"
            />
          </div>
          <div
            class="bg-gray-100 p-2.5 flex mb-1"
            v-else
            v-for="school in card.list"
          >
            <Text
              :content="school.name"
              size="sm"
              custom-class="w-3/5 align-middle truncate whitespace-nowrap"
              color="gray-900"
            />
            <div
              class="w-2/5 text-right"
              :class="`text-${themeColor}`"
              v-if="!isLocked(school)"
            >
              {{ getCalculatedData(school, "outOfPocket", "onCampus") }}
            </div>
            <div v-else class="w-2/5 flex flex-row-reverse">
              <ButtonWithIcon
                label="Locked"
                iconName="LockClosedIcon"
                text-color="gray-500"
                icon-size="3"
                class="w-fit bg-white border border-gray-500 text-xs px-2 py-1"
                :leading="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full py-5 flex justify-around flex-wrap">
      <ApplyButton v-for="item in getApplyCollages()" v-bind="item" />
    </div>
  </div>
</template>

<script>
import TabsWithTriangleRightBorder from "../../../molecules/Tabs/TabsWithTriangleRightBorder/TabsWithTriangleRightBorder.vue";
import Greeting from "../../../molecules/Greeting/Greeting.vue";
import Calendar from "../../../molecules/Calendar/Calendar.vue";
import DashboardButtons from "../../../molecules/DashboardButtons/DashboardButtons.vue";
import SchoolCard from "../../../molecules/Cards/SchoolCard/SchoolCard.vue";
import Text from "../../../atoms/Text/Text.vue";
import Button from "../../../atoms/Button/Button.vue";
import TabsInPills from "../../../molecules/Tabs/TabsInPills/TabsInPills.vue";
import Description from "../../../organisms/TermsAndPolicy/ContentItem/Item.vue";
import MultiSelectDropdown from "../../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown.vue";
import ApplyButton from "../../../molecules/ApplyButton/ApplyButton.vue";
import SchoolCompareCard from "../../../molecules/Cards/SchoolCompareCard/SchoolCompareCard.vue";
import ButtonWithIcon from "../../../molecules/Button/WithIcon/WithIcon.vue";
import SimpleRadioGroup from "../../../molecules/RadioGroup/Simple/Simple.vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import { c } from "@/components/constants.js";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";
import { BarChart } from "echarts/charts";
import Badge from "../../../molecules/Badge/Basic/Basic.vue";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import { use } from "echarts/core";
import { ref } from "vue";
import { _ } from "vue-underscore";
import numeral from "numeral";

use([
  BarChart,
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  components: {
    MultiSelectDropdown,
    TabsWithTriangleRightBorder,
    Greeting,
    Calendar,
    DashboardButtons,
    SchoolCard,
    Text,
    Button,
    ButtonWithIcon,
    TabsInPills,
    Description,
    ApplyButton,
    SchoolCompareCard,
    VChart,
    Icon,
    SimpleRadioGroup,
    Badge,
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    homeValuesShow(school) {
      const app = this.state.currentFinApp;
      if (app?.schoolList && school) {
        const matchSchool = _.find(app["schoolList"], (item) => {
          return item?.school?.id === school.id && item.isInStateSchool;
        });
        if (matchSchool?.id) {
          return school?.statusSchoolType === "STATE";
        } else {
          return false;
        }
      }
      return false;
    },
    getData() {
      return this.chartView?.value === "atHome"
        ? this.homeData["GrantsData"]
        : this.campusData["GrantsData"];
    },
    getArrayValueFn(value) {
      if (_.isArray(value)) {
        if (value[0] > 0) return value[0];
        else if (value[1] > 0) return value[1];
        else return 0;
      } else {
        return value;
      }
    },
    renderChart() {
      const compareList = _.sortBy(this.compareSchoolList, (school) => {
        if (this.locked)
          return -["Stanford University", "Rice University"].indexOf(
            school.name
          );
        else return school;
      });
      var barLabels = [];
      const CampusData = { FinalCostData: [], LoanData: [], GrantsData: [] };
      const HomeData = { FinalCostData: [], LoanData: [], GrantsData: [] };
      const nullArray = [{}, {}, {}, {}, {}, {}, {}, {}];
      let grantColor = "rgba(41, 186, 172, 0.6)",
        loanColor = "rgba(254, 144, 8, 0.6)",
        costColor = "rgba(41, 186, 172, 1)";
      _.each(_.first(compareList.concat(nullArray), 8), (school) => {
        if (school.id) {
          barLabels.push(school.name);

          if (school.calculationData && school.calculationData["onCampus"]) {
            if (
              this.locked &&
              school &&
              ["Rice University", "Stanford University"].indexOf(
                school.name
              ) === -1
            ) {
              CampusData["FinalCostData"].push({
                value: null,
                itemStyle: { color: "rgba(223, 226, 232, 0.5)" },
              });
              CampusData["GrantsData"].push({
                value: null,
                itemStyle: { color: "rgba(223, 226, 232, 0.7)" },
              });
              CampusData["LoanData"].push({
                value: null,
                itemStyle: { color: "rgba(223, 226, 232, 0.6)" },
              });
              HomeData["FinalCostData"].push({
                value: null,
                itemStyle: { color: "rgba(223, 226, 232, 0.5)" },
              });
              HomeData["GrantsData"].push({
                value: null,
                itemStyle: { color: "rgba(223, 226, 232, 0.7)" },
              });
              HomeData["LoanData"].push({
                value: null,
                itemStyle: { color: "rgba(223, 226, 232, 0.6)" },
              });
            } else {
              CampusData["FinalCostData"].push({
                value: this.getArrayValueFn(
                  school.calculationData["onCampus"]["outOfPocket"]
                ),
                itemStyle: { color: "rgba(41, 186, 172, 1)" },
              });
              CampusData["GrantsData"].push({
                value: this.getArrayValueFn(
                  school.calculationData["onCampus"]["totalGrants"]
                ),
                itemStyle: { color: "rgba(41, 186, 172, 0.6)" },
              });
              CampusData["LoanData"].push({
                value: this.getArrayValueFn(
                  school.calculationData["onCampus"]["loanAmount"]
                ),
                itemStyle: { color: "rgba(254, 144, 8, 0.6)" },
              });
              HomeData["FinalCostData"].push({
                value: this.getArrayValueFn(
                  school.calculationData["atHome"]["outOfPocket"]
                ),
                itemStyle: { color: "rgba(41, 186, 172, 1)" },
              });
              HomeData["GrantsData"].push({
                value: this.getArrayValueFn(
                  school.calculationData["atHome"]["totalGrants"]
                ),
                itemStyle: { color: "rgba(41, 186, 172, 0.6)" },
              });
              HomeData["LoanData"].push({
                value: this.getArrayValueFn(
                  school.calculationData["atHome"]["loanAmount"]
                ),
                itemStyle: { color: "rgba(254, 144, 8, 0.6)" },
              });
            }
          }
        } else {
          barLabels.push(" ");
        }
      });
      let maxValue = _.max(_.pluck(_.flatten(_.values(CampusData)), "value"));
      if (!maxValue) maxValue = 1000;
      else {
        maxValue = maxValue / 2;
        maxValue = Math.round(maxValue / 100) * 100 + 100;
      }
      //
      CampusData["FinalCostData"] = _.map(
        CampusData["FinalCostData"],
        (item) => {
          if (item.value == null) item.value = maxValue;
          return item;
        }
      );
      CampusData["GrantsData"] = _.map(CampusData["GrantsData"], (item) => {
        if (item.value == null) item.value = maxValue;
        return item;
      });
      CampusData["LoanData"] = _.map(CampusData["LoanData"], (item) => {
        if (item.value == null) item.value = maxValue;
        return item;
      });
      //
      HomeData["FinalCostData"] = _.map(HomeData["FinalCostData"], (item) => {
        if (item.value == null) item.value = maxValue;
        return item;
      });
      HomeData["GrantsData"] = _.map(HomeData["GrantsData"], (item) => {
        if (item.value == null) item.value = maxValue;
        return item;
      });
      HomeData["LoanData"] = _.map(HomeData["LoanData"], (item) => {
        if (item.value == null) item.value = maxValue;
        return item;
      });
      console.log(HomeData, CampusData);
      this.homeData = HomeData;
      this.campusData = CampusData;
      const option = {
        legend: {
          align: "left",
          left: 80,
          bottom: 0,
          textStyle: { fontFamily: "Montserrat", fontWeight: "500" },
          show: false,
        },
        tooltip: {
          trigger: "item",
          formatter: function (tooltip) {
            return tooltip.value != maxValue
              ? `<span style="font-weight: 500;">${tooltip.name}</span><br />${
                  tooltip.seriesName
                }${
                  tooltip.seriesName == "Upfront Cost" ||
                  tooltip.seriesName == "Grants"
                    ? "/yr"
                    : ""
                }: ${numeral(Math.round(tooltip.value)).format("$0,0")}`
              : ``;
          },
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          textStyle: {
            fontFamily: "Montserrat",
            fontSize: 12,
            fontWeight: "600",
            color: "#ffffff",
          },
        },
        xAxis: {
          type: "category",
          data: barLabels,
          axisLine: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            formatter: function (label) {
              const labelArr = label.split(" ");
              var val = "";
              for (var i = 0; i < labelArr.length; i++)
                val += `${labelArr[i]}\n`;
              return val;
            },
            fontFamily: "Montserrat",
            fontWeight: "500",
            lineHeight: 16,
            fontSize: 11,
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
          },
          axisLabel: {
            formatter: function (value) {
              return `$${value}`;
            },
            fontFamily: "Montserrat",
            fontWeight: "500",
          },
        },
        series: [
          {
            name: "Grants",
            data:
              this.chartView?.value === "atHome"
                ? HomeData["GrantsData"]
                : CampusData["GrantsData"],
            label: {
              show: false,
              color: "#fff",
              fontFamily: "Montserrat",
              fontWeight: "500",
              position: "inside",
              formatter: function (data) {
                return data?.value > 0 && data?.value !== maxValue
                  ? numeral(Math.round(data.value)).format("$0,0")
                  : "";
              },
            },
            type: "bar",
            stack: "school",
            colorBy: "data",
            itemStyle: { color: grantColor },
            // barMinHeight: 20,
          },
          {
            name: "Loans",
            data:
              this.chartView?.value === "atHome"
                ? HomeData["LoanData"]
                : CampusData["LoanData"],
            label: {
              show: false,
              color: "#fff",
              fontFamily: "Montserrat",
              fontWeight: "500",
              position: "inside",
              formatter: function (data) {
                return data?.value > 0
                  ? data?.value !== maxValue
                    ? numeral(Math.round(data.value)).format("$0,0")
                    : "Locked"
                  : "";
              },
            },
            type: "bar",
            stack: "school",
            colorBy: "data",
            itemStyle: { color: loanColor },
            // barMinHeight: 20,
          },
          {
            name: "Upfront Cost",
            data:
              this.chartView?.value === "atHome"
                ? HomeData["FinalCostData"]
                : CampusData["FinalCostData"],
            label: {
              show: false,
              color: "#fff",
              fontFamily: "Montserrat",
              fontWeight: "500",
              position: "inside",
              formatter: function (data) {
                return data?.value > 0 && data?.value !== maxValue
                  ? numeral(Math.round(data.value)).format("$0,0")
                  : "";
              },
            },
            type: "bar",
            stack: "school",
            colorBy: "data",
            itemStyle: { color: costColor },
            // barMinHeight: 20,
          },
        ],
      };
      this.chartOptions = option;
    },
    selectOptions() {
      return _.pluck(this.schoolList, "name");
    },
    onCompare() {
      const listIds = _.pluck(
        [...this.schoolList].filter((school) =>
          this.selectedSchools.includes(school?.name)
        ),
        "id"
      );
      this.compareSchoolList = _.filter([...this.appSchoolList], (item) =>
        listIds.includes(item.id)
      );
    },
    updateUserSchoolList(school) {
      if (this.$parent.$parent.updateUserSchoolList)
        this.$parent.$parent.updateUserVisionList(school);
    },
    isInStateSchool(school) {
      const isInStateSchool = [...this.userSchoolList].find(
        (item) => item.id === school.id && item.isInStateSchool
      );
      return isInStateSchool ? true : false;
    },
    isLocked(school) {
      if (
        this.locked &&
        school &&
        ["Rice University", "Stanford University"].indexOf(school.name) === -1
      )
        return true;
      return false;
    },
    getSummary() {
      const appListUniversity = _.filter([...this.appSchoolList], (item) =>
        _.pluck(
          [...this.userSchoolList].filter(
            (school) => school?.applicationType === "UC"
          ),
          "id"
        ).includes(item.id)
      );
      const appListCalState = _.filter([...this.appSchoolList], (item) =>
        _.pluck(
          [...this.userSchoolList].filter(
            (school) => school?.applicationType === "CAL_STATE"
          ),
          "id"
        ).includes(item.id)
      );
      const appListOther = _.filter([...this.appSchoolList], (item) =>
        _.pluck(
          [...this.userSchoolList].filter(
            (school) => school?.applicationType === "COMMON_APP"
          ),
          "id"
        ).includes(item.id)
      );

      const cards = [
        { title: "UNIVERSITY OF CALIFORNIA SCHOOLS", list: appListUniversity },
        { title: "CAL STATE SCHOOLS", list: appListCalState },
        { title: "ALL OTHER SCHOOLS", list: appListOther },
      ];
      return cards;
    },
    getApplyCollages() {
      return [...this.applyColleges].map((collage) => {
        if (collage.label === "Apply To UC Colleges") {
          collage.isAble =
            _.filter(
              [...this.appSchoolList],
              (item) => item?.applicationType === "UC"
            )?.length > 0;
        } else if (collage.label === "Apply To Cal State Colleges") {
          collage.isAble =
            _.filter(
              [...this.appSchoolList],
              (item) => item?.applicationType === "CAL_STATE"
            )?.length > 0;
        } else if (collage.label === "Apply To Common App Colleges") {
          collage.isAble =
            _.filter(
              [...this.appSchoolList],
              (item) => item?.applicationType === "COMMON_APP"
            )?.length > 0;
        }
        return collage;
      });
    },
  },
  watch: {
    userSchoolList: function (list) {
      this.schoolList = [...list];
      const listIds = _.pluck(
        [...list].filter((school) =>
          this.selectedSchools.includes(school?.name)
        ),
        "id"
      );
      this.compareSchoolList = _.filter([...this.appSchoolList], (item) =>
        listIds.includes(item.id)
      );
    },
    compareSchoolList: function (data) {
      this.renderChart();
    },
    chartView: function (data) {
      this.renderChart();
    },
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas",
      },
      chartView: { title: "LIVING ON CAMPUS", value: "onCampus" }, // onCampus, atHome,
      chartOptions: {},
      schoolList: this.userSchoolList,
      selectedSchools: _.pluck(_.first([...this.userSchoolList], 8), "name"),
      compareSchoolList: _.filter([...this.appSchoolList], (item) =>
        _.pluck(_.first([...this.userSchoolList], 8), "id").includes(item.id)
      ),
      chartTypeOptions: [
        { title: "LIVING ON CAMPUS", value: "onCampus" },
        { title: "LIVING AT HOME", value: "atHome" },
      ],
      homeData: null,
      campusData: null,
      numeral
    };
  },
  props: {
    header: {
      type: Object,
      default: () => {},
    },
    footer: {
      type: Object,
      default: () => {},
    },
    themeColor: {
      type: String,
      default: "primary",
    },
    backBtnLabel: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
    compareLabel: {
      type: String,
      default: "",
    },
    compareProps: {
      type: Object,
      default: null,
    },
    summary: {
      type: Object,
      default: null,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    selectedTabId: {
      type: Number,
      default: -1,
    },
    applyColleges: {
      type: Array,
      default: () => [],
    },
    userSchoolList: {
      type: Array,
      default: () => [],
    },
    locked: {
      type: Boolean,
      default: () => false,
    },
    getCalculatedData: {
      type: Function,
      default: () => "",
    },
    appSchoolList: {
      type: Array,
      default: () => [],
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.text-orange {
  color: #feb012;
}

.underline-dash {
  color: #ed8b00;
  border-bottom: 1px dashed;
}

.list-num {
  border: 1px solid #29baad;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  line-height: 20px;
  top: 1px;
  left: -30px;
  margin-right: 10px;
}

.list-circle-box ul {
  list-style: circle;
}

.fasfa-greeting-box ul li {
  margin-top: 10px;
}

.fasfa-greeting-box ul {
  list-style: none;
}

.fasfa-greeting-box ul li::before {
  content: "\2022";
  color: #29baad;
  font-weight: bold;
  display: inline-block;
  width: 12px;
  margin-left: -12px;
}

.btn-868c97 {
  background: white;
  border: 1px solid #868c97;
  color: #868c97;
}

.btn-868c97:hover {
  background: #868c97;
  color: white;
}

.school-card-container {
  flex: 1 1 calc(50% - 12px);
}

@media (min-width: 640px) {
  .sm\:mb-m-1 {
    margin-bottom: -1px !important;
  }
}

.w-870px {
  width: 870px !important;
}

.h-710px {
  height: 710px !important;
}

.w-520px {
  width: 520px !important;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.fix-table-wrapper {
  position: relative;
}

table.compare-table {
  table-layout: fixed;
}

table.compare-table .fix-table-col {
  position: sticky;
  left: 0;
  width: 250px;
  font-weight: 500;
  text-align: left;
}

.inner-fix-table-wrapper {
  /*overflow-x: scroll;*/
  /*overflow-y: visible;*/
  /*width: calc(100% - 250px);*/
  /*margin-left: 250px;*/
}

table.compare-table tr {
  position: relative;
}

table.compare-table tr > th,
table.compare-table tr > td {
  border: none !important;
  padding: 8px;
}

table.compare-table tr > th,
table.compare-table tr > td {
  width: 250px;
}

table.compare-table tbody tr:nth-child(even),
table.compare-table tbody tr:nth-child(even) .fix-table-col,
.school-slider-block .school-slider-row:nth-child(even) {
  background-color: #f2f2f2;
}

table.compare-table .compare-section {
  border-bottom: 1px solid #dee1e8;
  border-top: none;
}

table.compare-table .lock-status {
  position: relative;
  top: -2px;
  margin-bottom: -4px;
  padding: 4px 8px;
  border: 1px solid #dee1e8;
  font-size: 10px;
  border-radius: 4px;
  cursor: pointer;
}
.chart-type {
  position: absolute;
  top: -15px;
  left: 80px;
  z-index: 1;
}
.chart-type .radio-button .radio-text {
  font-size: 12px !important;
  font-weight: 700 !important;
}
</style>
