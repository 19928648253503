<template>
  <div class="w-full mt-10 sm:mt-0" v-if="isLoading">
    <CalculateLoader />
  </div>
  <div v-else class="mt-24 sm:mt-27 lg:mt-0">
    <div class="w-full border-b-0 sm:border-b">
      <div class="px-3.5 mx-auto w-7xl max-w-7xl">
        <!-- <Text :content="resultProps?.GreetingProps?.title" size="2xl" color="gray-800" weight="bold" customClass="my-8" /> -->
        <div class="flex flex-col justify-center items-center mt-5">
          <Text size="2xl" weight="extrabold" color="black" content="Save Money" />
          <Text size="xs" color="gray-500" v-html="`Total of Grants over 4 years waiting for you<br/>from our partner Colleges below`" weight="extrabold" customClass="text-center" />
          <div class="flex justify-center space-x-1 sm:space-x-2 my-5">
            <Text size="xl" color="primary" weight="extrabold" custom-class="mt-1 sm:mt-2 sm:text-3xl" :content="'$'" />
            <div v-for="c in chars" :key="c">
              <Text v-if="isNaN(parseInt(c))" size="xl" color="primary" weight="extrabold" customClass="mt-3 sm:text-4xl" :content="c" />
              <div v-else class="relative bg-primary rounded-md flex flex-col justify-center items-center sm:h-12 h-8 sm:w-10 w-6">
                <div class="absolute top-0 sm:h-6 h-4 border-white border-b rounded-t-md sm:w-10 w-6 bg-primary"></div>
                <div class="absolute bottom-0 sm:h-6 h-4 sm:w-10 w-6 rounded-b-md" style="background-color: #81d1c9"></div>
                <Text size="xl" color="white" weight="extrabold" customClass="absolute z-10 sm:text-4xl" :content="c" />
              </div>
            </div>
          </div>
        </div>
        <TabsWithTriangleRightBorder @changeTab="changeTab" :tabs="tabs" :selected-tab-id="currentTabId" />
      </div>
    </div>
    <Results v-if="currentTabId === 0" v-bind="resultProps" :getCalculatedData="(school, calcType, onCampusOrAtHome, isInSchool) => getCalculatedData(school, calcType, onCampusOrAtHome, isInSchool)" :schoolList="schoolList" @changeTab="changeTab" />
    <Compare v-if="currentTabId === 1" v-bind="compareProps" :getCalculatedData="(school, calcType, onCampusOrAtHome) => getCalculatedData(school, calcType, onCampusOrAtHome)" />
    <FafsaAndCss v-if="currentTabId === 2" v-bind="fafsaCssProps" />
    <Courses v-if="currentTabId === 3" v-bind="courseProps" />
    <Resources v-if="currentTabId === 4" v-bind="resourceProps" @handleShowMore="showMoreResource" @sendMessage="(message) => $emit('sendMessage', message)" ref="resourceRef" />
  </div>
</template>

<script>
import BackButton from "../../molecules/Button/BackButton/BackButton.vue";
import TabsWithTriangleRightBorder from "../../molecules/Tabs/TabsWithTriangleRightBorder/TabsWithTriangleRightBorder.vue";
import Greeting from "../../molecules/Greeting/Greeting.vue";
import Calendar from "../../molecules/Calendar/Calendar.vue";
import DashboardButtons from "../../molecules/DashboardButtons/DashboardButtons.vue";
import SchoolCard from "../../molecules/Cards/SchoolCard/SchoolCard.vue";
import Text from "../../atoms/Text/Text.vue";
import Button from "../../atoms/Button/Button.vue";
import Results from "../../organisms/Dashboard/Results/Results.vue";
import Compare from "../../organisms/Dashboard/Compare/Compare.vue";
import FafsaAndCss from "../../organisms/Dashboard/FafsaAndCss/FafsaAndCss.vue";
import Resources from "../../organisms/Dashboard/Resources/Resources.vue";
import Courses from "../../organisms/Dashboard/Courses/Courses.vue";
import CalculateLoader from "../../common/calculateLoader.vue";
import { _ } from "vue-underscore";
import numeral from "numeral";
import { isArray } from "lodash";

export default {
  components: {
    BackButton,
    TabsWithTriangleRightBorder,
    Greeting,
    Calendar,
    DashboardButtons,
    SchoolCard,
    Text,
    Button,
    Resources,
    Results,
    Compare,
    FafsaAndCss,
    Courses,
    CalculateLoader,
  },
  props: {
    themeColor: {
      type: String,
      default: "primary",
    },
    backBtnLabel: {
      type: String,
      default: "",
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    selectedTabId: {
      type: Number,
      default: -1,
    },
    resultProps: {
      type: Object,
      default: null,
    },
    compareProps: {
      type: Object,
      default: null,
    },
    courseProps: {
      type: Object,
      default: null,
    },
    fafsaCssProps: {
      type: Object,
      default: null,
    },
    resourceProps: {
      type: Object,
      default: null,
    },
    backAction: {
      type: Function,
    },
    showMoreResource: {
      type: Function,
    },
    isLoading: { type: Boolean },
    schoolList: {
      type: Array,
      default: () => [],
    },
    gpaBucket: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentTabId: this.selectedTabId,
      money: 0,
      numeral,
      chars: [],
    };
  },
  watch: {
    "resultProps.app": function (val) {
      const schools = [...Object.values(val?.InStateSchools), ...Object.values(val?.OutOfStateSchools)];
      let money = 0;
      console.log(schools, "schools");
      schools.map((s) => {
        if (s?.applicationType === "UC") {
          if (isArray(s?.calculationData?.atHome?.totalGrants)) {
            money += s?.calculationData?.atHome?.totalGrants?.sort((a, b) => b - a)?.[0] * 4;
          } else {
            money += s?.calculationData?.atHome?.totalGrants * 4;
          }
        } else {
          if (isArray(s?.calculationData?.onCampus?.totalGrants)) {
            money += s?.calculationData?.onCampus?.totalGrants?.sort((a, b) => b - a)?.[0] * 4;
          } else {
            money += s?.calculationData?.onCampus?.totalGrants * 4;
          }
        }
      });
      console.log(money, numeral(money).format("0,0").length);
      this.money = money > 0 ? money : 0;
      numeral(this.money)
        .format("0,0")
        .split("")
        ?.map((c) => this.chars.push(c));
      console.log(this.chars);
    },
  },
  methods: {
    changeTab(id) {
      console.log(id, "changeTab");
      this.currentTabId = id;
      if (id === 5) this.backAction();
    },
    getCalculatedData(school, calcType, onCampusOrAtHome, isInSchool) {
      const amount = 5742;
      if (school && school.calculationData) {
        if (calcType === "cost") {
          return numeral(school.calculationData[onCampusOrAtHome]["costOfAttendance"]).format("$0,0");
        } else if (calcType === "grants") {
          if (_.isArray(school.calculationData[onCampusOrAtHome]["totalGrants"])) {
            if (school.calculationData[onCampusOrAtHome]["totalGrants"][0] > school.calculationData[onCampusOrAtHome]["totalGrants"][1]) {
              return numeral(school.calculationData[onCampusOrAtHome]["totalGrants"][1] > 0 ? school.calculationData[onCampusOrAtHome]["totalGrants"][1] : 0).format("$0,0") + " - " + numeral(school.calculationData[onCampusOrAtHome]["totalGrants"][0] > 0 ? school.calculationData[onCampusOrAtHome]["totalGrants"][0] : 0).format("$0,0");
            } else {
              return numeral(school.calculationData[onCampusOrAtHome]["totalGrants"][0] > 0 ? school.calculationData[onCampusOrAtHome]["totalGrants"][0] : 0).format("$0,0") + " - " + numeral(school.calculationData[onCampusOrAtHome]["totalGrants"][1] > 0 ? school.calculationData[onCampusOrAtHome]["totalGrants"][1] : 0).format("$0,0");
            }
          } else {
            if (isInSchool && this.gpaBucket === "2.0 to 2.9") {
              if (onCampusOrAtHome == "atHome") {
                return this.getCalculatedData(school, "grants", "onCampus", isInSchool);
              } else if (onCampusOrAtHome == "onCampus") {
                if (school.calculationData[onCampusOrAtHome]["totalGrants"] >= amount) {
                  return numeral(school.calculationData[onCampusOrAtHome]["totalGrants"] - amount || 0).format("$0,0");
                } else {
                  return numeral(school.calculationData[onCampusOrAtHome]["totalGrants"] || 0).format("$0,0");
                }
              }
            } else {
              return numeral(school.calculationData[onCampusOrAtHome]["totalGrants"] > 0 ? school.calculationData[onCampusOrAtHome]["totalGrants"] : 0).format("$0,0");
            }
          }
        } else if (calcType === "loans") {
          if (_.isArray(school.calculationData[onCampusOrAtHome]["loanAmount"])) {
            return numeral(school.calculationData[onCampusOrAtHome]["loanAmount"][1] > 0 ? school.calculationData[onCampusOrAtHome]["loanAmount"][1] : 0).format("$0,0") + " - " + numeral(school.calculationData[onCampusOrAtHome]["loanAmount"][0] > 0 ? school.calculationData[onCampusOrAtHome]["loanAmount"][0] : 0).format("$0,0");
          } else {
            if (isInSchool && this.gpaBucket === "2.0 to 2.9") {
              if (onCampusOrAtHome == "atHome") {
                var tempGrants = school.calculationData["onCampus"]["totalGrants"] - amount;

                var tempLoan = school.calculationData["atHome"]["costOfAttendance"] - tempGrants;

                if (tempLoan > 5500) {
                  tempLoan = 5500;
                }

                return numeral(tempLoan).format("$0,0");
              } else if (onCampusOrAtHome == "onCampus") {
                return numeral(school.calculationData[onCampusOrAtHome]["loanAmount"] > 0 ? school.calculationData[onCampusOrAtHome]["loanAmount"] : 0).format("$0,0");
              }
            } else {
              return numeral(school.calculationData[onCampusOrAtHome]["loanAmount"] > 0 ? school.calculationData[onCampusOrAtHome]["loanAmount"] : 0).format("$0,0");
            }
          }
        } else if (calcType === "need") {
          return numeral(school.calculationData[onCampusOrAtHome]["need"] > 0 ? school.calculationData[onCampusOrAtHome]["need"] : 0).format("$0,0");
        } else if (calcType === "totalCost") {
          if (_.isArray(school.calculationData[onCampusOrAtHome]["totalCost"])) {
            return numeral(school.calculationData[onCampusOrAtHome]["totalCost"][0] > 0 ? school.calculationData[onCampusOrAtHome]["totalCost"][0] : 0).format("$0,0") + " - " + numeral(school.calculationData[onCampusOrAtHome]["totalCost"][1] > 0 ? school.calculationData[onCampusOrAtHome]["totalCost"][1] : 0).format("$0,0");
          } else {
            return numeral(school.calculationData[onCampusOrAtHome]["totalCost"] > 0 ? school.calculationData[onCampusOrAtHome]["totalCost"] : 0).format("$0,0");
          }
        } else if (calcType === "outOfPocket") {
          if (_.isArray(school.calculationData[onCampusOrAtHome]["outOfPocket"])) {
            if (school.calculationData[onCampusOrAtHome]["outOfPocket"].length === 1)
              if (isInSchool && this.gpaBucket === "2.0 to 2.9") {
                return numeral(school.calculationData[onCampusOrAtHome]["outOfPocket"][0] + (school.calculationData[onCampusOrAtHome]["totalGrants"] > amount ? amount : school.calculationData[onCampusOrAtHome]["totalGrants"])).format("$0,0");
              } else {
                return numeral(school.calculationData[onCampusOrAtHome]["outOfPocket"][0] > 0 ? school.calculationData[onCampusOrAtHome]["outOfPocket"][0] : 0).format("$0,0");
              }

            return school.calculationData[onCampusOrAtHome]["outOfPocket"][0] === school.calculationData[onCampusOrAtHome]["outOfPocket"][1] ? numeral(school.calculationData[onCampusOrAtHome]["outOfPocket"][0] > 0 ? school.calculationData[onCampusOrAtHome]["outOfPocket"][0] : 0).format("$0,0") : numeral(school.calculationData[onCampusOrAtHome]["outOfPocket"][0] > 0 ? school.calculationData[onCampusOrAtHome]["outOfPocket"][0] : 0).format("$0,0") + " - " + numeral(school.calculationData[onCampusOrAtHome]["outOfPocket"][1] > 0 ? school.calculationData[onCampusOrAtHome]["outOfPocket"][1] : 0).format("$0,0");
          } else {
            if (isInSchool && this.gpaBucket === "2.0 to 2.9") {
              if (onCampusOrAtHome == "atHome") {
                var theGrants = school.calculationData["onCampus"]["totalGrants"] - amount;
                var tempLoan = school.calculationData["atHome"]["costOfAttendance"] - theGrants;

                if (tempLoan > 5500) {
                  tempLoan = 5500;
                }

                return numeral(school.calculationData["atHome"]["costOfAttendance"] - theGrants - tempLoan).format("$0,0");
              } else if (onCampusOrAtHome == "onCampus") {
                return numeral(school.calculationData[onCampusOrAtHome]["outOfPocket"] + (school.calculationData[onCampusOrAtHome]["totalGrants"] > amount ? amount : school.calculationData[onCampusOrAtHome]["totalGrants"])).format("$0,0");
              }
            } else {
              return school.calculationData[onCampusOrAtHome]["outOfPocket"] ? numeral(school.calculationData[onCampusOrAtHome]["outOfPocket"] > 0 ? school.calculationData[onCampusOrAtHome]["outOfPocket"] : 0).format("$0,0") : numeral(0).format("$0,0");
            }
          }
        } else if (calcType === "award") {
          return numeral(school.calculationData[onCampusOrAtHome]["award"] > 0 ? school.calculationData[onCampusOrAtHome]["award"] : 0).format("$0,0");
        }
      } else {
        return numeral(0).format("$0,0");
      }
    },
    clearContactForm() {
      this.$refs?.resourceRef?.clearContactForm();
    },
  },
};
</script>

<style>
.btn-868c97 {
  background: white;
  border: 1px solid #868c97;
  color: #868c97;
}

.btn-868c97:hover {
  background: #868c97;
  color: white;
}

.school-card-container {
  flex: 1 1 calc(50% - 12px);
}

@media (min-width: 640px) {
  .sm\:mb-m-1 {
    margin-bottom: -1px !important;
  }
}
</style>
